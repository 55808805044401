const Testimonials = [
    {
        "content": "Lorem ipsum dolor sit amet. Et enim animi cum pariatur voluptas sed optio dignissimos ut eveniet repellendus! Est autem possimus aut optio accusantium sed facere autem.",
        "image": {
            "_type": "image",
            "asset": {
                "_ref": "image-25f57c1149f6028337ccd9aa00446904361a1654-800x800-jpg",
                "_type": "reference"
            }
        },
        "author": "Rahul Devan",
        "_createdAt": "2024-12-29T17:45:27Z",
        "_rev": "NuW1Xmv8KxLDhlqDq3DZRc",
        "_type": "testimonials",
        "_id": "4af23292-e4c7-413b-872c-47ae0e22358f",
        "_updatedAt": "2024-12-29T17:46:45Z"
    },
    {
        "author": "Vineesh Vijayan",
        "_createdAt": "2024-12-29T17:48:04Z",
        "_rev": "4vhePLOfDlc3DH46ztSFIR",
        "_type": "testimonials",
        "_id": "b2b2c8cb-8c3b-4d75-a9b3-a4f9eb44c902",
        "_updatedAt": "2024-12-29T17:48:23Z",
        "content": "Ab molestiae ullam aut ratione velit ut aspernatur fuga eos modi iste At laudantium magni ut facilis odio. You do not have to worry about it because you are a minor in suscipit because you are architect so that you are delectus maiores et delectus fugit. Ea expedita beatae id architecto dicta in aliquam consequatur eos illum incidunt et delectus dolor."
    },
    {
        "_type": "testimonials",
        "_id": "ef63cba2-2338-419a-a870-49bcfd168f8a",
        "_updatedAt": "2025-01-02T10:02:34Z",
        "content": "Ab molestiae ullam aut ratione velit ut aspernatur fuga eos modi iste At laudantium magni ut facilis odio. You do not have to worry about it because you are a minor in suscipit because you are architect so that you are delectus maiores et delectus fugit. Ea expedita beatae id architecto dicta in aliquam consequatur eos illum incidunt et delectus dolor.",
        "image": {
            "_type": "image",
            "asset": {
                "_ref": "image-7570a7fbe616be8e73300328a179aab37645b8f9-800x800-jpg",
                "_type": "reference"
            }
        },
        "author": "Alwin Tom",
        "_createdAt": "2024-12-29T17:46:51Z",
        "_rev": "4vhePLOfDlc3DH470Qsbhr"
    }
];
export default Testimonials;