// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-us {
  padding-top: 100px;
}

.contact-arrow {
  width: 16px;
  height: 15.79px;
  gap: 0px;
  opacity: 0px;
  margin-left: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/AboutUsComponent/AboutUsComponent.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAGA;EACI,WAAA;EACA,eAAA;EACA,QAAA;EACA,YAAA;EACA,gBAAA;AAAJ","sourcesContent":[".about-us {\n    padding-top: 100px;\n    // padding-bottom: 100px;\n}\n\n.contact-arrow {\n    width: 16px;\n    height: 15.79px;\n    gap: 0px;\n    opacity: 0px;\n    margin-left: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
