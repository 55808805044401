import { BrowserRouter, Route, Routes } from "react-router-dom";
import ContactComponent from "../components/ContactComponent/ContactComponent";
import HomeComponent from "../components/HomeComponent/HomeComponent";
import baseRoutes from "../constants/routes";
import ScrollToTop from "../lib/scrollToTop";
import HeaderComponent from "../components/HeaderComponent/HeaderComponent";
import ServicesDetailedView from "../components/ServicesDetailedView/ServicesDetailedView";

function AppRoutes() {
    return (
        <BrowserRouter>
            <HeaderComponent />
            <ScrollToTop />
            <Routes>
                <Route exact path={baseRoutes.Home} element={<HomeComponent />} />
            </Routes>
            <Routes>
                <Route exact path={baseRoutes.Contact} element={<ContactComponent />} />
            </Routes>
            <Routes>
                <Route exact path={baseRoutes.ServiceDetailed} element={<ServicesDetailedView />} />
            </Routes>
        </BrowserRouter>
    )
}

export default AppRoutes;