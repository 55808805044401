// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.service-section {
  padding-top: 100px;
  padding-bottom: 170px;
}

.services-list {
  padding-top: 12px;
}

.service-card-parent {
  margin-top: 20px !important;
  cursor: pointer;
  margin-top: 20px !important;
}

.service-card {
  box-shadow: 0px 0px 40px 0px rgba(106, 152, 190, 0.3019607843);
  border-radius: 20px !important;
  border: none !important;
  height: 100% !important;
}
.service-card .service-cover {
  height: 187px;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}
.service-card .service-details {
  padding: 24px;
}
.service-card .service-details .service-title {
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  text-align: left;
  color: #FFFFFF;
}
.service-card .service-details .service-description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #FFFFFF;
}
.service-card .service-details .section-next {
  width: 40px;
  height: 40px;
  float: right;
  cursor: pointer;
}
.service-card .service-details .section-next:hover {
  transform: scale(1.1);
}`, "",{"version":3,"sources":["webpack://./src/components/ServicesComponent/ServiceComponent.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,qBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,2BAAA;EACA,eAAA;EACA,2BAAA;AACJ;;AACA;EACI,8DAAA;EACA,8BAAA;EACA,uBAAA;EACA,uBAAA;AAEJ;AACI;EACI,aAAA;EACA,uCAAA;EACA,wCAAA;AACR;AAEI;EACI,aAAA;AAAR;AAEQ;EAEI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EAGA,cAAA;AAHZ;AAMQ;EAEI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EAGA,cAAA;AAPZ;AAUQ;EACI,WAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;AARZ;AAUY;EACI,qBAAA;AARhB","sourcesContent":[".service-section {\n    padding-top: 100px;\n    padding-bottom: 170px;\n}\n\n.services-list {\n    padding-top: 12px;\n}\n\n.service-card-parent {\n    margin-top: 20px !important;\n    cursor: pointer;\n    margin-top: 20px !important;\n}\n.service-card {\n    box-shadow: 0px 0px 40px 0px #6A98BE4D;\n    border-radius: 20px !important;\n    border: none !important;\n    height: 100% !important;\n    \n\n    .service-cover {\n        height: 187px;\n        border-top-left-radius: 20px !important;\n        border-top-right-radius: 20px !important;\n    }\n\n    .service-details {\n        padding: 24px;\n\n        .service-title {\n\n            font-size: 22px;\n            font-weight: 600;\n            line-height: 33px;\n            text-align: left;\n\n\n            color: #FFFFFF;\n        }\n\n        .service-description {\n\n            font-size: 16px;\n            font-weight: 400;\n            line-height: 24px;\n            text-align: left;\n\n\n            color: #FFFFFF;\n        }\n\n        .section-next {\n            width: 40px;\n            height: 40px;\n            float: right;\n            cursor: pointer;\n\n            &:hover {\n                transform: scale(1.1);\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
