import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";

export default function ContactUsForm() {
    const formRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
    });
    const [errors, setErrors] = useState({});

    const validateField = (name, value) => {
        let error = "";

        if (!value.trim()) {
            error = `${name.replace(/^\w/, (c) => c.toUpperCase())} is required.`;
        } else {
            if (name === "email" && !/\S+@\S+\.\S+/.test(value)) {
                error = "Enter a valid email address.";
            }
            if (name === "phone" && !/^\d{10}$/.test(value)) {
                error = "Enter a valid 10-digit phone number.";
            }
            if (name === "message" && value.trim().length < 3) {
                error = "Oops! Your message is too short. Please enter at least 3 characters.";
            }
        }

        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        validateField(name, value);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const validateForm = () => {
        let newErrors = {};
        Object.keys(formData).forEach((field) => {
            validateField(field, formData[field]);
            if (!formData[field].trim()) {
                newErrors[field] = `${field.replace(/^\w/, (c) => c.toUpperCase())} is required.`;
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const sendEmail = (e) => {
        e.preventDefault();

        if (!validateForm()) return;

        setIsLoading(true);

        emailjs
            .sendForm(
                "service_q7b8daw",
                "template_fn3cmxm",
                formRef.current,
                "iuCfxYJCuQoAcWuei"
            )
            .then(
                () => {
                    setToastMessage("Thank you! Our team will contact you shortly.");
                    setFormData({
                        firstName: "",
                        lastName: "",
                        email: "",
                        phone: "",
                        message: "",
                    });
                    setErrors({});
                },
                () => {
                    setToastMessage("Failed to send message. Please try again.");
                }
            )
            .finally(() => {
                setIsLoading(false);
            });
    };

    const closeToast = () => {
        setToastMessage("");
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    };

    return (
        <form ref={formRef} onSubmit={sendEmail} onKeyDown={handleKeyDown} noValidate>
            <div className="mb-3">
                <label htmlFor="firstName" className="form-label">First Name</label>
                <input
                    type="text"
                    className={`form-control ${errors.firstName ? "is-invalid" : ""}`}
                    id="firstName"
                    name="firstName"
                    placeholder="First name"
                    value={formData.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}
            </div>

            <div className="mb-3">
                <label htmlFor="lastName" className="form-label">Last Name</label>
                <input
                    type="text"
                    className={`form-control ${errors.lastName ? "is-invalid" : ""}`}
                    id="lastName"
                    name="lastName"
                    placeholder="Last name"
                    value={formData.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}
            </div>

            <div className="mb-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input
                    type="email"
                    className={`form-control ${errors.email ? "is-invalid" : ""}`}
                    id="email"
                    name="email"
                    placeholder="Email address"
                    value={formData.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.email && <div className="invalid-feedback">{errors.email}</div>}
            </div>

            <div className="mb-3">
                <label htmlFor="phone" className="form-label">Phone</label>
                <input
                    type="tel"
                    className={`form-control ${errors.phone ? "is-invalid" : ""}`}
                    id="phone"
                    name="phone"
                    placeholder="Phone number"
                    value={formData.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={(e) => {
                        if (!/^\d$/.test(e.key) &&
                            !["Backspace", "ArrowLeft", "ArrowRight", "Tab"].includes(e.key)) {
                            e.preventDefault();
                        }
                    }}
                />
                {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
            </div>


            <div className="mb-3">
                <label htmlFor="message" className="form-label">Message</label>
                <textarea
                    className={`form-control ${errors.message ? "is-invalid" : ""}`}
                    id="message"
                    name="message"
                    rows="4"
                    placeholder="Write your message here..."
                    value={formData.message}
                    onChange={handleChange}
                    onBlur={handleBlur}
                ></textarea>
                {errors.message && <div className="invalid-feedback">{errors.message}</div>}
            </div>

            <div className="d-grid">
                <button type="submit" className="btn btn-send" disabled={isLoading}>
                    {isLoading ? (
                        <div className="spinner-border spinner-border-sm text-light" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    ) : (
                        "Send"
                    )}
                </button>
            </div>

            {toastMessage && (
                <div className="toast-container position-fixed bottom-0 end-0 p-3">
                    <div className="toast show align-items-center text-bg-success border-0" role="alert">
                        <div className="d-flex">
                            <div className="toast-body">{toastMessage}</div>
                            <button type="button" className="btn-close btn-close-white me-2 m-auto" aria-label="Close" onClick={closeToast}></button>
                        </div>
                    </div>
                </div>
            )}
        </form>
    );
}
