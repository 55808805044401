// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.service-details-main .load-section {
  padding-top: 20px;
  padding-bottom: 170px !important;
}
.service-details-main section .ndash-footer {
  opacity: 100% !important;
}
@media screen and (max-width: 400px) {
  .service-details-main .service-detailed-view .contact-section {
    padding-bottom: 80px !important;
  }
}
.service-details-main .service-detailed-view .contact-section {
  padding-bottom: 170px !important;
  margin-top: 8%;
}
.service-details-main .service-detailed-view .details-img {
  padding-left: 0 !important;
  padding-right: 0 !important;
  max-height: 485px !important;
}
.service-details-main .service-detailed-view .service-content {
  padding: 5%;
  color: #ffffff;
}
.service-details-main .service-detailed-view .service-content .services-back {
  width: 32px;
  height: 32px;
  margin-bottom: 30px;
  cursor: pointer;
}
.service-details-main .service-detailed-view .service-content .service-heading {
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  text-align: left;
}
.service-details-main .service-detailed-view .service-content .service-content-desc {
  margin-top: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/ServicesDetailedView/ServicesDetailedView.scss"],"names":[],"mappings":"AACI;EACI,iBAAA;EACA,gCAAA;AAAR;AAKQ;EACI,wBAAA;AAHZ;AAQQ;EACI;IACI,+BAAA;EANd;AACF;AASQ;EACI,gCAAA;EACA,cAAA;AAPZ;AAUQ;EACI,0BAAA;EACA,2BAAA;EACA,4BAAA;AARZ;AAWQ;EACI,WAAA;EACA,cAAA;AATZ;AAWY;EACI,WAAA;EACA,YAAA;EACA,mBAAA;EACA,eAAA;AAThB;AAYY;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAVhB;AAaY;EACI,gBAAA;AAXhB","sourcesContent":[".service-details-main {\n    .load-section {\n        padding-top: 20px;\n        padding-bottom: 170px !important;\n    }\n\n\n    section {\n        .ndash-footer {\n            opacity: 100% !important;\n        }\n    }\n\n    .service-detailed-view {\n        @media screen and (max-width: 400px) {\n            .contact-section {\n                padding-bottom: 80px !important;\n            }\n        }\n\n        .contact-section {\n            padding-bottom: 170px !important;\n            margin-top: 8%;\n        }\n\n        .details-img {\n            padding-left: 0 !important;\n            padding-right: 0 !important;\n            max-height: 485px !important;\n        }\n\n        .service-content {\n            padding: 5%;\n            color: #ffffff;\n\n            .services-back {\n                width: 32px;\n                height: 32px;\n                margin-bottom: 30px;\n                cursor: pointer;\n            }\n\n            .service-heading {\n                font-size: 32px;\n                font-weight: 600;\n                line-height: 48px;\n                text-align: left;\n            }\n\n            .service-content-desc {\n                margin-top: 30px;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
