import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons/faWhatsapp";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons/faXTwitter";
import { faEllipsis, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SocialMediaResponsiveIconsComponent = () => {
    return (
        <main id="sm-icons-responsive">
            <div className="fab-container" id='scroll' style={{ display: "hidden" }}>
                <div className="fab shadow">
                    <div className="fab-content">
                        <FontAwesomeIcon color="#fff" icon={faEllipsis} />
                    </div>
                </div>
                <div className="sub-button shadow">
                    <a href="https://wa.me/+919176791836" aria-label="tel">
                        <FontAwesomeIcon color="#fff" icon={faWhatsapp} />
                    </a>
                </div>
                <div className="sub-button shadow">
                    <a href="https://www.twitter.com" aria-label="tel">
                        <FontAwesomeIcon icon={faXTwitter} />
                    </a>
                </div>
                <div className="sub-button shadow">
                    <a href="https://www.instagram.com" aria-label="tel">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                </div>
                <div className="sub-button shadow">
                    <a href="https://www.facebook.com" aria-label="tel">
                        <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                </div>
                <div className="sub-button shadow">
                    <a href="https://www.linkedin.com/company/ndash-private-limited/" aria-label="tel">
                        <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                </div>
                <div className="sub-button shadow">
                    <a href="tel:+919884402166" aria-label="tel">
                        <FontAwesomeIcon icon={faPhone} />
                    </a>
                </div>
                <div className="sub-button shadow">
                    <a href="mailto:contact@ndashdigital.com" aria-label="tel">
                        <FontAwesomeIcon icon={faEnvelope} />
                    </a>
                </div>
            </div>
        </main>
    );
}

export default SocialMediaResponsiveIconsComponent;