import { useState } from 'react';
import './SuccessStories.scss';
import imageUrlBuilder from "@sanity/image-url";
import { client, getSuccessStories } from '../../common/sanity-client';
import { useEffect } from 'react';
import Loader from '../LoaderComponent/LoaderComponent';

const builder = imageUrlBuilder(client);
function urlFor(source) {
    return builder.image(source);
}

export default function SuccessStoriesComponent() {
    const [successStoriesList, setSuccessStories] = useState([]);

    useEffect(() => {
        getSuccessStories()
            .then((data) => {
                setSuccessStories(data);
            })
            .catch(console.error);
    }, []);

    return (
        <section className="ss-container">
            <div className='ss-main-div'>
                <div className="container">
                    <div className='row'>
                        {!successStoriesList ||
                            successStoriesList.length == 0
                            &&
                            <div className='col-lg-12'>
                                <Loader />
                            </div>
                        }
                        <div className='col-lg-8 offset-lg-2 col-md-8 offset-md-2 col-12'>
                            {successStoriesList &&
                                successStoriesList.map((successStory, index) => (
                                    <div className={`row ss-card  ${index < successStoriesList.length - 1 && index > 0 ? "ss-card-border-bottom" : ""}`}>
                                        {index % 2 == 0
                                            &&
                                            <>
                                                <div className='col-lg-6 col-md-6 col-12 text-center'>
                                                    <img className="ss-image card-img-top" src={urlFor(successStory.image).url()} alt={successStory.title}></img>
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-12'>
                                                    <div className='row align-items-center h-100'>
                                                        <div className='col-lg-12 '>
                                                            <h3 className='ss-title'>
                                                                {successStory.title}
                                                            </h3>
                                                            <p className='ss-desc'>
                                                                {successStory.description}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>}
                                        {index % 2 == 1
                                            &&
                                            <>
                                                <div className='col-lg-6 col-md-5 col-12 d-none d-md-block d-lg-block'>
                                                    <div className='row align-items-center h-100'>
                                                        <div className='col-lg-12 '>
                                                            <h3 className='ss-title'>
                                                                {successStory.title}
                                                            </h3>
                                                            <p className='ss-desc'>
                                                                {successStory.description}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-12 text-center'>
                                                    <img className="ss-image card-img-top" src={urlFor(successStory.image).url()} alt={successStory.title}></img>
                                                </div>
                                                <div className='col-lg-6 col-md-5 col-12 d-md-none d-lg-none d-xs-block d-sm-block'>
                                                    <div className='row align-items-center h-100'>
                                                        <div className='col-lg-12 '>
                                                            <h3 className='ss-title'>
                                                                {successStory.title}
                                                            </h3>
                                                            <p className='ss-desc'>
                                                                {successStory.description}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>}
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}