import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import "./HeaderComponent.css";
// import { Nav, Navbar } from 'react-bootstrap';


const HeaderComponent = () => {


    return (
        <Navbar expand="lg" fixed="top">
            <Container>
                {/* <Navbar.Brand as={Link} to="/">
                    <a className="navbar-brand" href="/">
                        <img src='/assets/images/ndash.svg' alt="nDash Digital" className="logo" />
                    </a>
                </Navbar.Brand> */}
                <Navbar.Brand as={Link} to="/" onClick={() => window.scrollTo(0, 0)}>
                    <img src='/assets/images/ndash.svg' alt="nDash Digital" className="logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/">
                            <img src='/assets/images/service-logo.svg' alt="nDash Digital Home" className="small-icon" />
                        </Nav.Link>
                        <Nav.Link>
                            <HashLink to={"/#services"}>
                                Our Services
                            </HashLink>
                        </Nav.Link>
                        <Nav.Link>
                            <HashLink to={"/#clients"}>
                                Our Clients
                            </HashLink>
                        </Nav.Link>
                        <Nav.Link>
                            <HashLink to={"/#success-stories"}>
                                Success Stories
                            </HashLink>
                        </Nav.Link>
                        <Nav.Link as={Link} to="/contact">Contact Us</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>


    );
};

export default HeaderComponent;
