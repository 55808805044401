import { useNavigate, useParams } from "react-router"
import imageUrlBuilder from "@sanity/image-url";

import Footer from "../FooterComponent/FooterComponent";
import "./ServicesDetailedView.scss";
import { useEffect, useState } from "react";
import { client, getServiceBySlug } from "../../common/sanity-client";
import { PortableText } from "@portabletext/react";
import { RichTextComponents } from "../../lib/BlockRenderer ";
import ContactUsForm from "../ContactComponent/ContactUsForm";
import ContactHeader from "../ContactComponent/ContactUsHeader";
import Loader from "../LoaderComponent/LoaderComponent";

const builder = imageUrlBuilder(client);
function urlFor(source) {
    return builder.image(source);
}

export default function ServicesDetailedView() {
    const { slug } = useParams();
    const [servicesDetails, setServicesDetails] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        var x = getServiceBySlug(slug)
            .then((data) => {
                if (data && data.length > 0) {
                    setServicesDetails(data[0]);
                }
            })
            .catch(console.error);
    }, []);

    function navigateBack(data) {
        navigate("/");
    }

    return (
        <main className="service-details-main">
            {!servicesDetails || !servicesDetails.colorCode
                &&
                <div className="load-section container">
                    <Loader />
                </div>
            }
            {servicesDetails && servicesDetails.colorCode &&
                <section className="service-detailed-view container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{ backgroundColor: servicesDetails.colorCode, paddingBottom: "170px" }}>
                            <div className="row">
                                <img className="col-lg-12 details-img" src={urlFor(servicesDetails.image).url()} />
                                <div className="col-lg-12">
                                    <div className="service-content">
                                        <img src="/assets/images/back.svg" className="services-back" onClick={navigateBack} />
                                        <div className="service-heading">
                                            {servicesDetails.title}
                                            <div className="service-content-desc">
                                                <PortableText value={servicesDetails.content} components={RichTextComponents} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div className="container">
                                <div className="row p-4 contact-section">
                                    <div className="offset-md-1 col-md-10 offset-lg-1 col-lg-10">
                                        <ContactHeader />
                                        <ContactUsForm />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            }

            <Footer />
        </main>
    );
}