import { Helmet } from 'react-helmet';

import AboutUsComponent from '../AboutUsComponent/AboutUsComponent';
import ClientComponent from '../ClientComponent/ClientComponent';
import Footer from '../FooterComponent/FooterComponent';
import ServicesComponent from '../ServicesComponent/ServiceComponent';
// import SocialMediaComponent from '../SocialMediaIcons';
import SuccessStoriesComponent from '../SuccessStories/SuccessStories';
import TestimonialsComponent from '../TestimonialComponent/TestimonialComponent';
import './HomeComponent.scss';
export default function HomeComponent() {
    return (
        <>
            <main className='main-container'>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>nDash Digital Services</title>
                    <meta name="description" content="One stop solution for IT Services, Development support and Quality Assurance" />
                    <link rel="canonical" href={`${window.location.origin}`} />
                </Helmet>
                
                <AboutUsComponent />
                <ServicesComponent />
                <ClientComponent />
                <TestimonialsComponent />
                <SuccessStoriesComponent />
                <Footer />
            </main>
        </>
    );
}