// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    /* display: flex;
    align-items: center;
    width: 100%; */
    padding: 1% !important;
    background-color: rgba(255, 255, 255, 0.5);
    /* width: 100%; */
    /* display: flex;
    align-items: center; */
    /* position: absolute; */
}

.navbar-brand {
    display: flex;
    align-items: center;
    margin-right: 20px;

}

.logo {
    width: 115px;
    height: 25.78px;
    opacity: 1;

}

.navbar-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 20px;
}

.nav-item {
    margin: 0;
}

.nav-link {
    text-decoration: none !important;
    color: #5D778C;
    padding: 5px;
    display: flex;
    align-items: center;

    a {
        text-decoration: none !important;
        color: inherit !important;
    }
}

.nav-link:hover {
    text-decoration: underline;
}

.nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
}

.small-icon {
    width: 16px;
    height: 16px;
    margin-right: 25px;
    margin-left: 95px;
}`, "",{"version":3,"sources":["webpack://./src/components/HeaderComponent/HeaderComponent.css"],"names":[],"mappings":"AAAA;IACI;;kBAEc;IACd,sBAAsB;IACtB,0CAA0C;IAC1C,iBAAiB;IACjB;0BACsB;IACtB,wBAAwB;AAC5B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;;AAEtB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,UAAU;;AAEd;;AAEA;IACI,qBAAqB;IACrB,UAAU;IACV,SAAS;IACT,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,gCAAgC;IAChC,cAAc;IACd,YAAY;IACZ,aAAa;IACb,mBAAmB;;IAEnB;QACI,gCAAgC;QAChC,yBAAyB;IAC7B;AACJ;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,cAAc;IACd,oBAAoB;AACxB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;AACrB","sourcesContent":[".navbar {\n    /* display: flex;\n    align-items: center;\n    width: 100%; */\n    padding: 1% !important;\n    background-color: rgba(255, 255, 255, 0.5);\n    /* width: 100%; */\n    /* display: flex;\n    align-items: center; */\n    /* position: absolute; */\n}\n\n.navbar-brand {\n    display: flex;\n    align-items: center;\n    margin-right: 20px;\n\n}\n\n.logo {\n    width: 115px;\n    height: 25.78px;\n    opacity: 1;\n\n}\n\n.navbar-list {\n    list-style-type: none;\n    padding: 0;\n    margin: 0;\n    display: flex;\n    align-items: center;\n    gap: 20px;\n}\n\n.nav-item {\n    margin: 0;\n}\n\n.nav-link {\n    text-decoration: none !important;\n    color: #5D778C;\n    padding: 5px;\n    display: flex;\n    align-items: center;\n\n    a {\n        text-decoration: none !important;\n        color: inherit !important;\n    }\n}\n\n.nav-link:hover {\n    text-decoration: underline;\n}\n\n.nav-link.disabled {\n    color: #6c757d;\n    pointer-events: none;\n}\n\n.small-icon {\n    width: 16px;\n    height: 16px;\n    margin-right: 25px;\n    margin-left: 95px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
