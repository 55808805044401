export default function ContactHeader() {
    return (
        <div className="row mb-4">
            <div className="col-12">
                <h1 className="fw-bold">Contact Us</h1>
                <p className="text-muted">
                Have questions or need assistance? Reach out to our team—we're here to help you with all your IT needs!
                </p>
            </div>
        </div>
    );
}