import imageUrlBuilder from "@sanity/image-url";
import { useEffect, useState } from 'react';
import { client, getTestimonials } from '../../common/sanity-client';
import './TestimonialComponent.scss';
import Loader from "../LoaderComponent/LoaderComponent";
import Testimonials from "../../lib/sanity-backup/testimonials";

const builder = imageUrlBuilder(client);
function urlFor(source) {
    return builder.image(source);
}

function getFirstCharacters(name) {
    var nameArray = name.trim().split(" ");
    if (nameArray.length > 1) {
        return (nameArray[0][0] + nameArray[1][0]).toUpperCase();
    } else {
        return (nameArray[0][0] + nameArray[0][1]).toUpperCase();
    }
}

export default function TestimonialsComponent() {

    const [testimonialList, setTestimonialList] = useState([]);

    useEffect(() => {
        var x = getTestimonials()
            .then((data) => {
                data.forEach((testimonial) => {
                    testimonial.image = urlFor(testimonial.image).url();
                });
                setTestimonialList(data);
            })
            .catch(console.error)
            .finally(() => {
                if (testimonialList.length == 0) {
                    Testimonials.forEach((testimonial) => {
                        if (testimonial.image) {
                            testimonial.image = "../../../assets/images/testimonials/" + testimonial._id + ".avif";
                        }
                    })
                    setTestimonialList(Testimonials);
                }
            });
    }, []);

    return (
        <section className='testimonial-container' id='success-stories'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h3 className='section-name'>
                            What People Say?
                        </h3>
                    </div>
                </div>
                <div className='row testimonial-list'>
                    {!testimonialList || testimonialList.length == 0
                        &&
                        <Loader />
                    }
                    {testimonialList &&
                        testimonialList.map((testimonial, index) => (
                            <div className='col-lg-4'>
                                <div className='card testimonial-card'>
                                    <div className="testimonial-img"
                                        nametext={testimonial.image ? "" : getFirstCharacters(testimonial.author)}
                                        style={{
                                            "--bg-img": testimonial.image ? "url('" + testimonial.image + "')" : ""
                                        }}>
                                    </div>
                                    <div className='testimonial-contents'>
                                        <h6 className='testimonial-name'>{testimonial.author}</h6>
                                        <img className='testimonial-quotes' src='/assets/images/quotes.svg'></img>
                                        <p className='testimonial-text'>{testimonial.content}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </section>
    );
}