import "./LoaderComponent.scss";

export default function Loader() {
    return (
        <>
            <div className="loader-container">
                <div className="row">
                    <div className="col-lg-3 col-3">
                        <div className="loader-sq"></div>
                    </div>
                    <div className="col-lg-9 col-9">
                        <div class="loader-l1"></div>
                        <div class="loader-l2"></div>
                        <div class="loader-l3"></div>
                        <div class="loader-l4"></div>
                    </div>
                </div>
            </div>

        </>
    );
}