import { Link } from 'react-router-dom';
import './FooterComponent.scss';

export default function Footer() {
    return (
        <section>
            <div className="ndash-footer">
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6'>
                            <div className='row'>
                                <div className='col-lg-8 col-md-11'>
                                    <h4 className='footer-header'>GET IN TOUCH</h4>
                                    <p className='footer-text'>Your communication is important to us. Please send us a message, and we will prioritize a timely response from our team.</p>
                                    <div className='footer-contact-details'>
                                        <div className='row'>
                                            <div className='col-lg-1 col-1 contact-telephone'></div>
                                            <div className='col-lg-11 col-11'>
                                                <a href='tel:+919884402166'>+91 9884 402 166</a>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-1 col-1 contact-telephone'></div>
                                            <div className='col-lg-11 col-11'>
                                                <a href='tel:+919176791836'>+91 9176 791 836</a>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-1 col-1 contact-email'></div>
                                            <div className='col-lg-11 col-11'>
                                                <a href='mailto:contact@ndashdigital.com'>contact@ndashdigital.com</a>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-1 col-1 contact-map'></div>
                                            <div className='col-lg-11 col-11'>
                                                3/314, Manappally North, <br />
                                                Thazhava, Thodiyoor,<br />
                                                Kollam, Kerala, 690574
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 col-lg-12'>
                                                <Link className='footer-direction-link' to={"https://maps.app.goo.gl/BsKqDaCLiZZznYP49"} target='_blank'>Get Direction in Google Maps</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 footer-p2'>
                            <div className='row footer-divider'>
                                <div className='col-lg-6 offset-lg-1 col-md-10 offset-md-1'>
                                    <h4 className='footer-header'>Our Services</h4>
                                    <h5 className='footer-secondary-header'>Web Application Development</h5>
                                    <h5 className='footer-secondary-header'>Mobile Application Development</h5>
                                    <h5 className='footer-secondary-header'>Cloud Solutions</h5>
                                    <h5 className='footer-secondary-header'>Quality Assurance</h5>
                                    <h5 className='footer-secondary-header'>App Support</h5>
                                    <h5 className='footer-secondary-header'>Devops Management</h5>
                                    <h5 className='footer-secondary-header'>AI Services</h5>
                                    <h5 className='footer-secondary-header'>App Moderization</h5>
                                    <div className='footer-email-content'>
                                        <h4 className='footer-header'>SHARE YOUR EMAIL</h4>
                                        <input type='text' className='form-control' placeholder='Enter email Id'></input>
                                        <button className='btn footer-send-email-btn'>Get started</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-12 footer-copyright'>
                            <div className='footer-copyright'>
                                <div className='footer-copyright-company-name'>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <img src='/assets/images/logo.svg' />
                                            <span>nDash</span>
                                        </div>
                                        <div className='col-lg-12'>
                                            <div className='footer-copyright-desc'>Copyright © 2024 nDash Inc. All rights reserved.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}