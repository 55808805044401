import './App.css';
import SocialMediaComponent from './components/SocialMediaIcons';
import AppRoutes from './routes/app.route';

function App() {
  return (
    <div className="App">
      <AppRoutes />
      <SocialMediaComponent />
    </div>
  );
}

export default App;
