import './ContactComponent.scss';
import ContactUsForm from './ContactUsForm';
import ContactHeader from './ContactUsHeader';
import Footer from '../FooterComponent/FooterComponent';

export default function ContactComponent() {
    return (
        <>
            <section className="contact-section">
                <div className="container">
                    <div className='text-center'>
                        <ContactHeader />
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6">
                            <ContactUsForm />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>

    );
}