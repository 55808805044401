import { faFacebookF, faInstagram, faLinkedinIn, faWhatsapp, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React from "react";
import "./SocialMediaComponent.scss";
import SocialMediaResponsiveIconsComponent from './SocialMediaResponsiveIcons';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
const SocialMediaComponent = () => {
    return (
        <>
            <div className="social-media-bar">
                <div style={{ height: '100px', width: '1px', marginLeft: '15px', backgroundColor: '#ccc', marginBottom: '5px' }}></div>
                <a href="https://wa.me/+919176791836" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faWhatsapp} />
                </a>
                <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faXTwitter} />
                </a>
                <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href="https://www.linkedin.com/company/ndash-private-limited/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
                <a href="tel:+919884402166" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faPhone} />
                </a>
                <a href="mailto:contact@ndashdigital.com" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faEnvelope} />
                </a>
            </div>
            <div className='social-media-bar-responsive'>
                <SocialMediaResponsiveIconsComponent />
            </div>
        </>

    );
};

export default SocialMediaComponent;
