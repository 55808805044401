// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/assets/images/bg-1.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../public/assets/images/bg-3.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../public/assets/images/bg-2.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}), url(${___CSS_LOADER_URL_REPLACEMENT_1___}), url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-attachment: fixed, fixed, fixed;
  background-size: 25% 100%, 45% 100%, 70% 100vh;
  background-position-x: 88%, 88%, 25%;
}

@media screen and (max-width: 768px) {
  .main-container {
    background-size: 75% 100%, 45% 100%, 70% 100vh;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/HomeComponent/HomeComponent.scss"],"names":[],"mappings":"AAAA;EACI,2IAAA;EACA,kDAAA;EACA,0CAAA;EACA,8CAAA;EACA,oCAAA;AACJ;;AAEA;EACI;IACI,8CAAA;EACN;AACF","sourcesContent":[".main-container {\n    background-image: url(\"../../../public/assets/images/bg-1.png\"), url(\"../../../public/assets/images/bg-3.png\"), url(\"../../../public/assets/images/bg-2.png\");\n    background-repeat: no-repeat, no-repeat, no-repeat;\n    background-attachment: fixed, fixed, fixed;\n    background-size: 25% 100%, 45% 100%, 70% 100vh;\n    background-position-x: 88%, 88%, 25%;\n}\n\n@media screen and (max-width: 768px) {\n    .main-container {\n        background-size: 75% 100%, 45% 100%, 70% 100vh;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
