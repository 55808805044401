// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-section {
  padding: 3rem 0;
  padding-bottom: 170px;
  padding-top: 80px;
}
.contact-section h1 {
  font-weight: bold;
  color: #333;
}
.contact-section p {
  color: #6c757d;
}
.contact-section form .form-label {
  font-size: 0.9rem;
  color: #555;
}
.contact-section form .form-control {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 0.8rem;
  font-size: 1rem;
}
.contact-section form .form-control:focus {
  border-color: #fe7206;
  box-shadow: 0 0 5px rgba(254, 114, 6, 0.5);
}
.contact-section form .btn-send {
  background-color: #fe7206;
  color: #fff;
  font-weight: bold;
  border: none;
  padding: 0.8rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}
.contact-section form .btn-send:hover {
  background-color: rgb(208.164, 91.124, 0.836);
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/ContactComponent/ContactComponent.scss"],"names":[],"mappings":"AAIA;EACE,eAAA;EACA,qBAAA;EACA,iBAAA;AAHF;AAKE;EACE,iBAAA;EACA,WAAA;AAHJ;AAME;EACE,cAAA;AAJJ;AASI;EACE,iBAAA;EACA,WAAA;AAPN;AAUI;EACE,sBAAA;EACA,kBAAA;EACA,eAAA;EACA,eAAA;AARN;AAUM;EACE,qBA/BQ;EAgCR,0CAAA;AARR;AAaI;EACE,yBAtCU;EAuCV,WAAA;EACA,iBAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,sCAAA;AAXN;AAaM;EACE,6CA9CM;EA+CN,WAAA;AAXR","sourcesContent":["// Define variables for reusability\n$primary-color: #fe7206;\n$hover-color: darken($primary-color, 10%);\n\n.contact-section {\n  padding: 3rem 0;\n  padding-bottom: 170px;\n  padding-top: 80px;\n\n  h1 {\n    font-weight: bold;\n    color: #333;\n  }\n\n  p {\n    color: #6c757d;\n  }\n\n  // Style the form\n  form {\n    .form-label {\n      font-size: 0.9rem;\n      color: #555;\n    }\n\n    .form-control {\n      border: 1px solid #ddd;\n      border-radius: 5px;\n      padding: 0.8rem;\n      font-size: 1rem;\n\n      &:focus {\n        border-color: $primary-color;\n        box-shadow: 0 0 5px rgba($primary-color, 0.5);\n      }\n    }\n\n    // Send button styling\n    .btn-send {\n      background-color: $primary-color;\n      color: #fff;\n      font-weight: bold;\n      border: none;\n      padding: 0.8rem;\n      border-radius: 5px;\n      transition: background-color 0.3s ease;\n\n      &:hover {\n        background-color: $hover-color;\n        color: #fff;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
